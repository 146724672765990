import AutoComplete from 'material-ui/AutoComplete';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Formsy from 'formsy-react';
import formsyUtils from 'formsy-react/lib/utils';
import { isInputWithError } from '../../core/utils';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';

const defaultStyle = {fontSize: '13px'}
const Autocomplete = React.createClass({
  mixins: [
    {
      ...Formsy.Mixin,
      ...{
        resetValue: function resetValue() {
          this.setState(
            {
              _value: this.state._pristineValue,
              _isPristine: true,
              searchText: '',
            },
            function() {
              this.context.formsy.validate(this);
            },
          );
        },
        setValue: function setValue(value) {
          if (formsyUtils.isSame(value, this.getValue())){
            return;
          }
          clearTimeout(this.blurTimer);
          const searchText = this.textRenderer(value);

          this.setState(
            {
              _value: value,
              _isPristine: false,
              searchText: searchText,
            },
            function() {
              this.context.formsy.validate(this);
              if (this.props.sendOnlyObjectValue && typeof value === 'string') {
                this.props.updateInput && this.props.updateInput(this.state.searchText)
              }
              else {
                this.props.onNewRequest && this.props.onNewRequest(value);
              }
              //this.props._validate(this);
            }.bind(this),
          );
        },
        getInitialState() {
         const value = this.props.value || this.props.defaultValue || void 0;
         const text = this.textRenderer(value);
          return {
            dataSource: [],
            allValues: [],
            searchText: text,
            _value: value,
            _isRequired: false,
            _isValid: true,
            _isPristine: true,
            _pristineValue: value,
            _validationError: [],
            _externalError: null,
            _formSubmitted: false,
            readonly: true,
            uuid: uuidv4(),
          };
        },
      },
    },
  ],

  defaultProps: {
    forceSelection: true,
    filter: AutoComplete.noFilter,
    invalid: undefined,
    errorText: '',
    shouldShowError: isInputWithError,
    limit: 100,
    delayTextValue: false,
  },
  componentDidMount: function() {
    if (this.props.defaultValue) {
      this.setValue(this.props.defaultValue);
    }
    this.fetchAllData();
    if (this.props.customRef) {
      this.props.customRef(this);
    }
  },

  fetchAllData() {
    if (this.props.openOnFocus) {
      this.Api
        .find(Object.assign({ name: null }, this.props.params))
        .then(res => {
          this.setState({ allValues: this.renderer(res.data) });
        });
    }
  },
  /*resetValue:function(value){
    console.log(value);
   },*/
  onUpdateInput: function(searchText, dataSource, params) {
    if(params?.source === 'touchTap') {
      return;
    }
    clearTimeout(this.commitTimer);
    this.setState({ searchText: searchText });
    if (this.state._value && this.props.forceSelection) {
      //this.props.onNewRequest && this.props.onNewRequest(void 0);
      if(params?.source === 'touchTap') {
        const value = dataSource.find(d => d.text === searchText)?._value;
        if(value) {
          this.setValue(value);
          if(this.props.onChange) this.props.onChange(null,value)
          return;
        }
      }
      this.setValue(void 0);
      this.setState({ searchText: searchText });
    }

    if (!this.props.forceSelection) {
      if (this.props.delayTextValue) {
        clearTimeout(this.commitTimer);
        this.commitTimer = setTimeout(() => {
          this.setValue(searchText);
        }, 500);
      } else {
        this.setValue(searchText);
      }
    }

    if (searchText && searchText.length > 1) {
      this.find(searchText.replace(/ \([A-Z]{3,3}\) ?$/, ''));
      /*if (!this.props.forceSelection) {
        this.commitTimer = setTimeout(() => {
          if (!this.state.dataSource || this.state.dataSource.length === 0) {
            this.props.onNewRequest && this.props.onNewRequest(this.state.searchText);
          }
        }, 500);
      }*/
    } else {
      this.setState({ dataSource: [] });
      clearTimeout(this.timer);
    }
  },
  onNewRequest: function(chosenRequest, index) {
    clearTimeout(this.commitTimer);
    clearTimeout(this.blurTimer);
    let value;
    if (chosenRequest._value) {
      value = chosenRequest._value;
    } else {
      value = this.state.dataSource[0] && this.state.dataSource[0]._value;
    }
    if (value && value !== this.getValue()) {
      this.setState({ searchText: this.textRenderer(value) });
      this.setValue(value);
      if(this.props.onChange) this.props.onChange(null,value)
      /*if (this.props.onNewRequest) {
        this.props.onNewRequest(value);
      }*/
    }
  },

  find: function(query) {
    const lowerQuery = query.toLowerCase();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.Api
        .find(Object.assign({ name: query }, this.props.params))
        .then(res => {
          if(this.props.sortFn) {
            res.data.sort(this.props.sortFn.bind(this,lowerQuery));
          }
          this.setState({ dataSource: this.renderer(res.data) }, () => {
            document.body.scrollTop = document.body.scrollTop + 1;
            document.body.scrollTop = document.body.scrollTop - 1;
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({ dataSource: [] });
        });
    }, 300);
  },

  focus: function() {
    this.refs.input.focus();
    clearTimeout(this.blurTimer);
  },

  renderer: function(items) {
    return items.slice(0, this.props.limit).map(item => {
      return {
        text: item.name,
        _value: item,
        value: <MenuItem style={{fontSize: '13px'}} primaryText={item.name} />,
      };
    });
  },
  onBlur: function (e) {
    if (this.props.forceSelection) {
     this.blurTimer = setTimeout(()=> {
          if (this.state.searchText && !this.getValue()) {
            const suitable = this.state.dataSource.filter(item => item.text.toLowerCase() === this.state.searchText.toLowerCase());
            if (suitable.length === 1) {
              const val = suitable[0];
              if (val) {
                this.onNewRequest(val);
              }
            }
          }
      },500);
    } else {
      const val = this.getValue();
      if (typeof val === "string") {
        const trimmed = val.trim();
        if (val !== trimmed) {
          this.setValue(trimmed);
        }
      }
    }
  },
  textRenderer: function (value) {
    return (value?.name || value?.code) ? (value.name || value.code) : typeof value === 'string' ? value : '';
  },
  render: function(styles = {}) {
    const {
      params,
      forceSelection,
      requiredError,
      validationErrors,
      previousValue,
      className ='',
      id,
      textFieldStyle = {},
      isRequiredError,
      shouldShowError = isInputWithError,
      value,
      onChange,
      ...props
    } = this.props;

    const _textFieldStyle = {...defaultStyle, ...textFieldStyle};
    let { className: cn = "", errorText } = shouldShowError(this);
    cn += ` sn-autocomplete ${(styles.className || '')}`;
    if (
      previousValue !== void 0 &&
      previousValue.name !== this.state.searchText
    ) {
      cn += ' dirty';
    }
    let dataSource = this.state.dataSource;
    if(this.props.openOnFocus && (this.state._value || !this.state.searchText)) {
      dataSource = this.state.allValues;
    }
    const popoverProps = props.popoverProps || { canAutoPosition: true, scrollableContainer: '.Signup-root-3T5Xh' };
    const disabled = this.props.disabled || this.isFormDisabled();
    let searchText = this.state.searchText;
    if(this.props.showEmptyOnDisabled){
      searchText = this.state.searchText ? this.state.searchText :  disabled ? '—' : ''
    }

    // change position of dropdown list when it off the page
    if (this.refs.input) {
      const inputElement = this.refs.input.refs.searchTextField.input;

      if (inputElement) {
        const autocompleteRect = inputElement.getBoundingClientRect();
        const distanceToBottom = window.innerHeight - autocompleteRect.bottom;
        if (distanceToBottom < 300) {
          popoverProps.style = {
            ...popoverProps.style,
            transform: `translateY(calc(-100% - ${autocompleteRect.height}px)`,
          };
        } else {
          popoverProps.style = {
            ...popoverProps.style,
            transform: 'none',
          };
        }
      }
    }
    return (
      <AutoComplete
        {...props}

        textFieldStyle={_textFieldStyle}
        id={id || props.name}
        filter={this.props.filter || AutoComplete.noFilter}
        searchText={searchText}
        onUpdateInput={this.onUpdateInput}
        onNewRequest={this.onNewRequest}
        dataSource={
          dataSource
        }
        disabled={disabled}
        fullWidth={props.notFullWidth ? false : true}
        menuStyle={{ ...props.menuStyle, maxHeight: '300px', overflowY: 'auto' }}
        listStyle={{...props.listStyle}}
        data-valid={!this.getErrorMessage() && this.props.invalid !== true}
        errorText={errorText}
        ref="input"
        popoverProps={{ ...popoverProps, className: `${popoverProps.className || ""} _AUTOCOMPLETE_POPOVER_OPEN` }}
        autoComplete={"off"}
        spellCheck="off"
        autoCorrect="off"
        type={'search'}
        onBlur={this.onBlur}
        {...styles}
        className={cx(cn, this.props.required && 'required', !this.state._value && 'empty')}
      />
    );
  },
});

/*AutoComplete._resetValue=AutoComplete.resetValue;
AutoComplete.resetValue=function(value){
alert('reset');
 this._resetValue(value);
 };*/

export default Autocomplete;
