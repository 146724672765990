import React from 'react'
import styled from 'styled-components'

export default function EmptyList({ Icon, title, description, isMaterialIcon=false, maxTextWidth='540px', titleStyle }) {
  return (
    <StyledComponent style={titleStyle}>
        {isMaterialIcon ? 
            <span className="material-icons">{Icon}</span>
            : <Icon width='100px' height='100px' className={'icon ship_icon_big'} fill={'#D2D2D2'} />}
            {title}
            {Array.isArray(description) && description.length > 0 ? 
            description.map((singleDescription)=><span style={{maxWidth:maxTextWidth}} className='empty-state'>{singleDescription}</span>)
            : <span style={{maxWidth:maxTextWidth}} className='empty-state'>{description}</span>}
    </StyledComponent>
  )
}
const StyledComponent = styled.div`
font-size: 20px;
line-height: 28px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
gap:8px;
flex-direction: column;
color:var(--stroke-light-gray1);
font-weight: 400;
.material-icons {
    font-size: 140px;
    color:var(--stroke-light-gray1);
}
.ship_icon_big svg{
    width: 86px !important;
    height: 95px !important;
    margin: 22px 0;
    transform: none;
    fill:  #D2D2D2 !important;
  }

  .ship_icon_big  g{
    fill: #D2D2D2 !important;
    opacity: 1!important;
  }

.empty-state{
  color: #D2D2D2;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
`
